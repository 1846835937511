/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link } from "gatsby"
import Icons from "../util/socialmedia.json"
import {
  RiFacebookBoxFill,
  RiTwitterFill,
  RiLinkedinBoxFill,
  RiYoutubeFill,
  RiInstagramFill,
  RiRssFill,
  RiGithubFill,
  RiTelegramFill,
  RiPinterestFill,
  RiSnapchatFill,
  RiSkypeFill,
  RiDribbbleFill,
  RiMediumFill,
  RiBehanceFill,
  RiMailFill,
} from "react-icons/ri"
import { FaWordpress, FaVk } from "react-icons/fa"

const Footer = () => {
  const sIcons = Icons.socialIcons.map(icons => {
    return (
      <div key={icons.icon + Date.now()}>
        {icons.icon === "facebook" ? (
          <Link to={icons.url} target="_blank">
            <RiFacebookBoxFill />
          </Link>
        ) : (
          ""
        )}
        {icons.icon === "email" ? (
          <Link to={"mailto:" + icons.url} target="_blank">
            <RiMailFill />
          </Link>
        ) : (
          ""
        )}
        {icons.icon === "twitter" ? (
          <Link to={icons.url} target="_blank">
            <RiTwitterFill />
          </Link>
        ) : (
          ""
        )}
        {icons.icon === "linkedin" ? (
          <Link to={icons.url} target="_blank">
            <RiLinkedinBoxFill />
          </Link>
        ) : (
          ""
        )}
        {icons.icon === "youtube" ? (
          <Link to={icons.url} target="_blank">
            <RiYoutubeFill />
          </Link>
        ) : (
          ""
        )}
        {icons.icon === "instagram" ? (
          <Link to={icons.url} target="_blank">
            <RiInstagramFill />
          </Link>
        ) : (
          ""
        )}
        {icons.icon === "rss" ? (
          <Link to={icons.url} target="_blank">
            <RiRssFill />
          </Link>
        ) : (
          ""
        )}
        {icons.icon === "github" ? (
          <Link to={icons.url} target="_blank">
            <RiGithubFill />
          </Link>
        ) : (
          ""
        )}
        {icons.icon === "telegram" ? (
          <Link to={icons.url} target="_blank">
            <RiTelegramFill />
          </Link>
        ) : (
          ""
        )}
        {icons.icon === "pinterest" ? (
          <Link to={icons.url} target="_blank">
            <RiPinterestFill />
          </Link>
        ) : (
          ""
        )}
        {icons.icon === "snapchat" ? (
          <Link to={icons.url} target="_blank">
            <RiSnapchatFill />
          </Link>
        ) : (
          ""
        )}
        {icons.icon === "skype" ? (
          <Link to={icons.url} target="_blank">
            <RiSkypeFill />
          </Link>
        ) : (
          ""
        )}
        {icons.icon === "wordpress" ? (
          <Link to={icons.url} target="_blank">
            <FaWordpress />
          </Link>
        ) : (
          ""
        )}
        {icons.icon === "dribbble" ? (
          <Link to={icons.url} target="_blank">
            <RiDribbbleFill />
          </Link>
        ) : (
          ""
        )}
        {icons.icon === "medium" ? (
          <Link to={icons.url} target="_blank">
            <RiMediumFill />
          </Link>
        ) : (
          ""
        )}
        {icons.icon === "behance" ? (
          <Link to={icons.url} target="_blank">
            <RiBehanceFill />
          </Link>
        ) : (
          ""
        )}
        {icons.icon === "vk" ? (
          <Link to={icons.url} target="_blank">
            <FaVk />
          </Link>
        ) : (
          ""
        )}
      </div>
    )
  })
  return (
    <footer
      className="site-footer"
      sx={{
        bg: "#000",
      }}
    >
      <div
        className="container"
        style={{
          height: "75px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "0px 30px",
        }}
      >
        <div className="social-icons" sx={footerStyles.socialIcons}>
          {sIcons}
        </div>
        <div
          style={{
            fontSize: "10px",
            color: "#999",
            marginLeft: "16px",
            textAlign: "left",
          }}
        >
          Photo by{" "}
          <a href="https://unsplash.com/@jonathan_christian_photography?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">
            Jonathan Riley
          </a>{" "}
          on{" "}
          <a href="https://unsplash.com/s/photos/nyc-empire-state?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">
            Unsplash
          </a>
        </div>
      </div>
    </footer>
  )
}

export default Footer

const footerStyles = {
  socialIcons: {
    a: {
      color: "socialIcons",
      ":hover": {
        color: "socialIconsHover",
      },
    },
  },
}
